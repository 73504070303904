import styled from 'styled-components';
import ArrowImage from '../../images/arrow-green.png';
import LinkArrowImage from '../../images/arrow-blue-small.png';

export const MainDiv = styled.div`
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.05em;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;
export const HeaderH1 = styled.h1`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #00483a;
  font-size: 45px;
  line-height: 50px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 40px;
  }
`;
export const HeaderH2 = styled.h2`
  margin: 0 0 25px 0;
  padding: 0;
  font-family: 'Tungsten-Medium', arial, sans-serif;
  font-weight: normal !important;
  color: #33a23c;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  padding-top: 10px;
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 30px 0;
  padding: 0;
  b {
    font-family: 'Gotham-Bold', arial, sans-serif;
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
  code {
    font-size: 19px;
    line-height: 28px;
    color: #666;
    font-family: 'Gotham-Book', arial, sans-serif;
    letter-spacing: 0.02em;
    font-style: normal !important;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
`;

export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #008168;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;

export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
export const OrderedList = styled.ol`
  margin: 0 0 30px 0;
  padding: 0;
  list-style-type: none;
  counter-reset: standard-counter;
  list-style-type: none;
`;

export const ListItem = styled.li`
  position: relative;
  margin: 0;
  padding: 15px 0 15px 50px;
  border-bottom: 1px solid #d9d9d9;
  p {
    margin: 0;
  }
  ::before {
    content: counter(standard-counter);
    counter-increment: standard-counter;
    position: absolute;
    left: 0;
    top: 11px;
    width: 26px;
    height: 21px;
    text-align: center;
    padding: 2px 0 3px 0;
    font-family: 'Tungsten-Medium', arial, sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #33a23c;
    border: 2px solid #33a23c;
    border-radius: 50%;
    box-sizing: content-box;
    letter-spacing: 0.05em;
  }
`;
export const ButtonStyle = styled.a`
  code {
    display: inline-block;
    padding: 16px 85px 14px 15px;
    margin: 0 5px 5px 0;
    font-family: 'Tungsten-Medium', arial, sans-serif;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase !important;
    text-align: left;
    color: #33a23c;
    background: #def6e0 url(${LinkArrowImage}) no-repeat 99% center;
    background-size: 23px auto;
    -webkit-transition: background-position 0.15s ease-out;
    -moz-transition: background-position 0.15s ease-out;
    -o-transition: background-position 0.15s ease-out;
    -ms-transition: background-position 0.15s ease-out;
    transition: background-position 0.15s ease-out;
    text-decoration: none;
    border: none;
    cursor: pointer;
    letter-spacing: 0.05em;
    @media (max-width: 767px) {
      width: 100% !important;
      margin: 0 0 25px 0 !important;
      :first-child {
        margin-bottom: 0 !important;
      }
    }
    :hover {
      background-position: 95% center;
      text-decoration: none;
      color: #0c4724;
    }
  }
`;

export const ImageStyle = styled.img`
  float: right;
  max-width: 75%;
  height: auto;
  margin: 0 0 20px 20px;
  border: 0;
  vertical-align: top;
  padding: 10px;
  background: #fff;
  font-size: 14px;
  line-height: 16px;
  @media (max-width: 767px) {
    float: none;
    margin: 0 0 30px 0;
    padding: 10px;
    max-width: 225px !important;
  }
  @media (max-width: 399px) {
    margin: 0 0 30px 0 !important;
    width: 60% !important;
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const SpanStyle = styled.span`
  position: absolute;
  top: 460px;
  @media (max-width: 991px) {
    top: -60px;
  }
  @media (max-width: 767px) {
    top: -45px;
  }
`;

export const SuperScriptStyle = styled.sup`
  vertical-align: top;
  font-size: 18px;
  line-height: 30px;
`;
